.side--navbar--system {
  @extend .sideNavbar--list;
  overflow-y: hidden;
  padding: 15px 12px;
  justify-content: flex-start;
  border: thin inset #17202a;
  min-width: 20%;
  flex-basis: 20%;
  animation: popUp 0.5s ease;
  & button {
    padding: 10%;
  }
}
