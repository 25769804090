@import 'constants';
@import 'resets';

.login--container {
  @include flexContainer(row, center, flex-start);
  height: auto;
  min-height: 100vh;
  color: $color-form;
  background-color: $overlay-bg-color;
  transition: background-color 1s ease-out;
  position: absolute;
  width: 100%;
  top: 0;
  animation: popUp 1s ease;
}

.login--modal {
  @include flexContainer(column, space-evenly, center);
  background-color: $modal-bg-color;
  height: auto;
  width: 500px;
  margin: 10% 0 0 0;
  gap: 12px;
  padding: 30px 40px;
  box-shadow: 0 0.5rem 1rem $navbar-bg-color;
  border-radius: 12px;
  border-style: thin solid $modal-bg-color;
  box-sizing: border-box;
}

.key--icon--container {
  display: flex;
}

.key--icon {
  color: $key-icon-color-a;
  transform: rotate(45deg);
  // Media-query
  font-size: 7rem;
}

.form--title {
  @include flexContainer(row, flex-start, center);
  font-size: 2.2rem;
  letter-spacing: 2px;
  margin: 20px 0px;
}

.form--container {
  @include flexContainer(column, center, space-between);
  gap: 30px;
  width: 100%;
}

.form--field {
  @include flexContainer(column, center, none);
  gap: 5px;
  & label {
    font-size: 0.8rem;
    font-weight: 700;
    letter-spacing: 1px;
    color: $navbar-text-color;
  }

  & input {
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: thin solid $key-icon-color-a;
    color: $navbar-text-color;
    padding: 12px;
  }
}

.button--form--style {
  @include flexContainer(row, center, center);
  background-color: transparent;
  color: $key-icon-color-a;
  padding: 10px;
  border: thin solid $key-icon-color-a;
  border-radius: 5px;
  font-weight: 700;
  min-width: 80px;
  letter-spacing: 1px;
  &:hover {
    cursor: pointer;
    background-color: $key-icon-color-a;
    color: $color-form;
    transition: background-color 1s ease-out;
  }
}

.icon--spin {
  color: $key-icon-color-a;
  font-size: 1.4rem;
  animation: spin 2s infinite linear;
  &--small {
    @extend .icon--spin;
    font-size: 1.2rem;
    color: $tertiary;
  }
}

.container--button {
  @include flexContainer(row, center, center);
}
