$navbar-bg-color: #343a40;
$navbar-bg-landing-page-color: #adadad;
$navbar-text-color: #adadad;
$form-label-color: #707070;
$overlay-bg-color: rgb(0, 0, 0, 0.5);
$modal-bg-color: #1a2226;
$key-icon-color-a: #0db8de;
$key-icon-color-c: #0db8de9a;
$key-icon-color-b: #27ef9f;
$color-form: #fafafa;
$form-bg-color: #f8f9fa;
$side-navbar-bg-color: #17202a;
$on--focus--color: #5e9ed6;
$important-color: #c70039;
$close-color: #c70039;
$close-hover-color: #8a0229;
$form-background: #e5e8e8;
$form--success-color: #2ecc71;
$selected-item: #8080806b;
$item-client-form: #686767d8;
$client-form-color-gray: #74747480;
$table-footer-color: #343a40;
$interline-table-color: #a1a1a146;
$table-items-color: #17202a;
$modal-content-background: #f8f9fa;
$alert-color: #ffcc00;
$alert-color2: #c29f12;
$error-color: #c73e1d;
$error-color2: #f18f01;
$secondary: #242a2d;
$tertiary: #ced2d7;
$dark-1: #373440;
$primary: #343a40;
$primary-golden: #6a7682;
