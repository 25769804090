@import './constants';

* {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Verdana',
    'sans-serif';
  font-size: 15px;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: $navbar-bg-landing-page-color;
}

// Mixin to create flex containers
@mixin flexContainer($direction, $justify, $align) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
}

// Mixin to create fonts styles easily
@mixin fontSettings($family, $size, $weight, $style) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  font-style: $style;
}

@mixin fontFadeColor($colorA, $colorB) {
  background: -webkit-linear-gradient($colorA, $colorB);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes popUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes dropDownItems {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dropDownArrow {
  0% {
    rotate: (0deg);
  }
  100% {
    rotate: (90deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
