@import './constants';
@import './resets';

.report--content--container {
  animation: popUp 1s ease;
  @include flexContainer(row, space-between, flex-start);
  border-radius: 12px;
  width: 100%;
}

.content--item {
  animation: popUp 1s ease;
  @include flexContainer(row, flex-start, flex-start);
  align-content: flex-start;
  flex-basis: 78%;
  padding: 30px;
  height: 70vh;
  & form {
    @include flexContainer(column, flex-start, flex-start);
    width: 100%;
  }
}

.form--first--level {
  @include flexContainer(row, space-between, center);
  padding: 20px 0;
  width: 100%;
  & div {
    @include flexContainer(row, none, center);
    flex-basis: 50%;
  }
}

.form--second--level {
  @include flexContainer(row, space-between, center);
  padding: 20px 0;
  width: 100%;
  & div {
    @include flexContainer(row, none, center);
    flex-basis: 50%;
  }
}
