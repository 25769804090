@import './constants';
@import './resets';

.navbar {
  @include flexContainer(row, center, center);
  box-shadow: 0 0.5rem 1rem $navbar-bg-color;
  background-color: $navbar-bg-color;
  color: $navbar-text-color;
  padding: 15px 3px;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
}

.navbar--items {
  @include flexContainer(row, space-between, center);
  max-width: 1270px;
  padding-right: 2%;
  list-style: none;
  margin: 0 auto;
  width: 100%;
}

.navbar--logo {
  @include flexContainer(row, center, end);
  margin-left: -4%;
  gap: 10px;
}

.project--version {
  color: $navbar-text-color;
  font-size: 13px;
  text-decoration: none;
}

.navbar--login {
  @include flexContainer(row, center, center);
  gap: 7px;
  text-decoration: none;
  cursor: pointer;
  color: $navbar-text-color;
  transition: color 1s ease-out;
  flex-basis: 10%;
}

.navbar--user--item {
  @include flexContainer(row, space-evenly, center);
  flex-basis: 70%;
  font-weight: 700;
}

.navbar--login:hover {
  color: #fafafa;
}

.navbar--icon {
  font-size: 2rem;
}

.drop--down--menu {
  cursor: pointer;
  position: relative;
  width: 100%;
}

.menu--title {
  background-color: transparent;
  color: $navbar-text-color;
  border: none;
  min-width: 150px;
  cursor: pointer;
}

.collapse--content {
  display: none;
}

.dropdown-content {
  position: absolute;
  @include flexContainer(column, space-evenly, flex-start);
  width: 110%;
  background-color: $navbar-bg-color;
  color: #fafafa;
  align-content: flex-start;
  box-sizing: border-box;
  height: 25vh;
  padding: 0 10px;
  box-shadow: 0px 28px 20px -10px rgba(0, 0, 0, 0.77);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 10px;
  z-index: 0;
  animation: popUp 1s ease;
  & a {
    font-family: 'Lato';
    font-weight: 700;
    text-decoration: none;
    color: $navbar-text-color;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  & a:hover {
    color: #fafafa;
  }
}
