@import './constants';
@import './resets';

.body--container {
  position: relative;
}

.forms--template {
  @include flexContainer(column, flex-start, none);
  color: $navbar-bg-color;
  max-width: 1870px;
  margin: 0 auto;
  margin-top: 1.25rem;
  background-color: $form-bg-color;
  padding: 20px;
  gap: 12px;
  animation: popUp 1s ease-in-out;
  & h1 {
    font-family: 'Lato', sans-serif;
    color: $color-form;
    @include flexContainer(row, center, center);
    width: 100%;
    padding: 12px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    background-color: $navbar-bg-color;
    border-radius: 22px;
  }
}

.sideNavbar--list {
  @include flexContainer(column, flex-start, start);
  color: $color-form;
  // height: 70vh;
  padding: 30px 10px 20px 0;
  border-radius: 15px;
  width: 26%;
  gap: 10px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.72);
  background-color: $navbar-bg-color;
  flex-basis: 22%;
  overflow-y: scroll;
  overflow-x: hidden;
  animation: popUp 0.5s ease;
  height: 700px;
}

.sideNavbar--list--clients {
  width: 20%;
  padding-left: 10px;
  overflow-y: hidden;
  justify-content: space-evenly;
  flex-basis: 20%;
  height: 640px;
}

.sideNavbar--item {
  width: 100%;
  display: flex;
  justify-content: start;
  padding: 0 20px;
  flex-direction: column;
  align-content: start;
  align-items: flex-start;
  gap: 7px;

  & .collapse--list--item {
    list-style: none;
    width: 100%;
    margin-left: 12%;
    padding: 5px;
    cursor: pointer;
    animation: dropDownItems 0.5s ease-in;
  }

  & .item--selected {
    display: inline;
    background-color: $selected-item;
  }

  & div {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: $color-form;
    @include flexContainer(row, center, center);
    gap: 5px;
    font-weight: 700;
  }

  & .arrow--initial--position {
    font-size: 1.2rem;
    transform: rotate(0deg);
    color: $navbar-text-color;
    transition: all 0.5s;
  }

  & .arrow--rotate {
    font-size: 1.2rem;
    transform: rotate(90deg);
    color: $color-form;
    transition: all 0.5s;
  }
}

.form--label {
  color: $navbar-bg-color;
  font-family: sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  font-size: 1.2rem;
  flex-basis: 50%;
  & span {
    color: $important-color;
  }
}

.form--label--client {
  @extend .form--label;
  font-size: 1rem;
  color: $item-client-form;
}

.form--input {
  margin-left: 1%;
  width: 50%;
  text-align: center;
  cursor: pointer;
  border: 2px solid $form-background;
  background-color: $form-background;
  padding: 5px;
  border-radius: 13px;
  font-family: sans-serif;

  &:focus {
    padding: 5px;
    outline: none;
    border: none;
    border: 2px solid $on--focus--color;
  }
}

.form--input--modal {
  @extend .form--input;
  text-align: start;
  padding-left: 12px;
}

.form--textarea {
  @extend .form--input;
  width: 80%;
  text-align: start;
  text-indent: 1%;
  resize: none;
}

.form--input--client {
  @extend .form--input;
  accent-color: $navbar-bg-color;
  color: $form-label-color;
  border-radius: 0;
  border: 1px dashed $navbar-text-color;
  cursor: not-allowed;
  pointer-events: none;
  transition: background-color 0.5s ease-in;
  &:focus {
    border: 1px dashed $navbar-text-color;
  }
}

.form--input--solver {
  @extend .form--input--client;
  text-align: start;
}

.container--button {
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 20px;
}

.form--level {
  @include flexContainer(row, space-between, center);
  padding: 20px 0;
  width: 100%;
  height: 10vh;
  animation: popUp 1s ease;
}

.form--input--container {
  width: 100%;
  @include flexContainer(row, end, center);
  gap: 10px;
}

.form--checked {
  display: inline;
}

.form--not--checked {
  visibility: hidden;
}

.pop--message {
  color: $important-color;
  font-weight: 700;
  @include flexContainer(row, start, center);
  gap: 2px;
  animation: popUp 1s;
}

.message--loading--container {
  animation: popUp 1s ease;
  @include flexContainer(column, center, center);
  width: 100%;
  gap: 22px;

  & div {
    @include flexContainer(column, center, center);
  }

  & h2 {
    font-size: 2rem;
  }
}

.success--icon {
  color: $form--success-color;
  font-size: 4rem;
}

.error--icon {
  color: $important-color;
  font-size: 4rem;
}

.info--icon {
  color: $alert-color;
  margin-bottom: 10px;
  font-size: 4rem;
}

.solver--button--container {
  @include flexContainer(row, end, center);
  gap: 12px;
  width: 100%;
}

// .solver--data {
//     @include flexContainer(column, center, start);
//     align-content: start;
//     gap: 12px;
//     padding-bottom: 30px;
//     & form {
//         height: 16rem;
//         @include flexContainer(column, space-between, center);
//         gap: 15px;
//         width: 100%;
//     }
// }
