@import './resets';

.home--container {
  @include flexContainer(row, center, center);
  margin: 0 auto;
  position: relative;
  max-width: 1300px;
  flex-wrap: wrap;
  gap: 40px 100px;
  margin-top: 2rem;
  padding: 20px;
  animation: popUp 1s ease;
}

.data--container {
  box-shadow: -1px 26px 14px -6px rgba(0, 0, 0, 0.75);
  @include flexContainer(column, center, center);
  gap: 10px;
  border-style: thin ridge $navbar-bg-color;
  background-color: $navbar-bg-color;
  color: $navbar-text-color;
  flex-basis: 33%;
  padding: 30px 50px;
  border-radius: 15px;
  flex-wrap: wrap;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background-color: $key-icon-color-a;
    color: $color-form;
    transition: all 1s;
  }
}
.container--name {
  font-size: 1.5rem;
}

.home--container--icon {
  font-size: 7rem;
}
