@import './resets';

.landing--page--bg {
  @include flexContainer(row, center, end);
  position: relative;

  // @media-query: It needs to be updated depending on the screen size.
  height: auto;
  padding-top: 20%;

  & .landing--img {
    width: 100%;
    opacity: 0.5;
  }
}
