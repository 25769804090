@import './constants';
@import './resets';

.clients--container {
  & form {
    @include flexContainer(row, space-evenly, center);
    padding: 5px 0 0 0;
    width: 100%;

    & .form--client-field {
      @include flexContainer(row, center, center);
      gap: 10px;

      & label {
        font-family: 'Lato', sans-serif;
        letter-spacing: 0.7px;
        color: $form-label-color;
      }
      & input:focus {
        padding: 3px;
        outline: none;
        border: 2px solid $navbar-bg-color;
      }
    }
  }
}

.button--form {
  height: 3rem;
  @include flexContainer(row, center, center);
  background-color: $navbar-bg-color;
  border: 1px solid transparent;
  border-radius: 30px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  padding: 0.75rem 1.2rem;
  text-align: center;
  text-decoration: none #6b7280 solid;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  min-width: 90px;
  &:hover {
    background-color: #374151;
  }

  &--warning {
    @extend .button--form;
    width: 100%;
    border-radius: 12px;
    background-color: $navbar-bg-color;
    font-weight: 600;
  }
  &--disabled {
    @extend .button--form;
    background-color: $primary-golden;
    &:hover {
      background-color: $primary-golden;
    }
  }
}

.button--form:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  .button--form {
    padding: 0.75rem 1.5rem;
  }
}

.client--buttons {
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 5% 8px;
  border-radius: 15px;
  color: $secondary;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.72);
  height: 10%;
  &:hover {
    font-weight: 700;
    color: #fafafa;
    background-color: $navbar-bg-color;
    transition: background-color 0.5s ease;
  }
}
.container--results {
  padding: 15px;
  border-radius: 15px;
  min-height: 550px;
}

.selected--button {
  background-color: $on--focus--color;
  box-shadow: none;
  color: #fafafa;
}

.client--container--title {
  @include flexContainer(row, center, center);
  padding: 10px;
  width: 100%;
  font-size: 1.5rem;
  letter-spacing: 1px;
}

.client--result--container {
  @include flexContainer(row, center, space-between);
  justify-content: space-between;
}
.data--result--container {
  flex-basis: 90%;
  padding: 0 10px;
  @include flexContainer(column, start, space-between);
  animation: popUp 1s ease;
  & form {
    @include flexContainer(column, center, start);
  }
}

.client--info--container {
  animation: popUp 1s ease;
}
.form--row {
  @include flexContainer(row, space-between, center);
  width: 100%;
}

.field--form {
  padding: 5px 10px;
  flex-basis: 50%;
  @include flexContainer(row, start, center);
  & input {
    flex-basis: 70%;
  }

  & label {
    flex-basis: 30%;
  }

  & select {
    flex-basis: 70%;
  }

  & textarea {
    flex-basis: 70%;
  }
}

.field--form--wallet {
  @extend .field--form;
  & input {
    flex-basis: 80%;
    border-radius: 7px;
  }
  & label {
    font-size: 1.1rem;
    flex-basis: 20%;
  }

  & select {
    border-radius: 7px;
    flex-basis: 90%;
  }
}

.field--form--textarea {
  @extend .field--form--wallet;
  align-items: flex-start;
}

.on--edit {
  box-sizing: border-box;
  padding: 2px 5px;
  pointer-events: default;

  & [type='select'] {
    cursor: pointer;
  }

  & [type='input'] {
    cursor: text;
  }
  animation: popUp 1s ease;
  letter-spacing: 0.5px;
}

.client--container--header {
  width: 100%;
  @include flexContainer(row, center, center);
  border-bottom: 1px ridge $client-form-color-gray;
  margin-bottom: 12px;
  padding: 5px;
}

.table--container {
  @include flexContainer(row, center, center);
  margin: 0 auto;
  padding: 10px;
  width: 70%;
}

.table--wallet {
  animation: popUp 1s ease;
}

.table--style {
  width: 100%;
  color: $client-form-color-gray;
  border-collapse: separate;
  border-spacing: 0 5px;
  & tr {
    border: 1px solid $item-client-form;
    color: $table-items-color;
  }

  tbody {
    & tr {
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.72);
    }
    & tr:hover {
      background-color: $primary-golden;
      color: #fafafa;
      box-shadow: none;
      font-weight: 400;
      cursor: pointer;
    }
  }
  thead {
    & tr {
      padding: 5px;
    }
  }
  & th {
    background-color: $secondary;
    box-shadow: none;
    color: #ffffff;
    padding: 5px 15px;
  }
  & td {
    padding: 10px;
    // color: $secondary;
    // border: 1px solid $item-client-form;
  }
  & .item--center {
    accent-color: $navbar-bg-color;
    text-align: center;
  }
  & .item--start {
    text-align: start;
  }
}

.table--style--no--borders {
  @extend .table--style;
  & th,
  td {
    border: none;
  }
}
.check--item {
  @extend .field--form;
  flex-basis: 20%;
  justify-content: space-evenly;

  & input {
    flex-basis: 20%;
    cursor: pointer;
  }

  & label {
    flex-basis: 20%;
  }
}
.buttons--align {
  text-align: end;
  width: 100%;
}

.container--error--message {
  @include flexContainer(row, start, center);
  width: 100%;
  animation: popUp 1s ease;
  padding: 10px 40px;
}

.error--message {
  text-align: center;
  width: 100%;
}

.flex--container {
  @include flexContainer(column, end, center);
  gap: 5px;
  &--message {
    @extend .flex--container;
    padding: 30px 0;
    font-weight: 600;
  }
  &--row {
    @extend .flex--container;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    font-weight: 700;
  }
}

.error--container {
  @include flexContainer(column, start, center);
  gap: 30px;
  width: 65%;
  & h3 {
    font-size: 1.3rem;
  }
}

.flex--button {
  animation: popUp 1s ease;
  @include flexContainer(row, center, center);
  width: 100%;
}

.icon--style {
  font-size: 1.5rem;
  color: #ffffff;
}

.button--transparent {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.buttons--container {
  width: 100%;
  padding: 6px 10px;
  @include flexContainer(row, end, center);
  gap: 12px;
}

.button--container--form--client {
  @extend .buttons--container;
  @include flexContainer(row, end, center);
}

.edit--icon {
  font-size: 1.35rem;
}

.trigger--icon {
  font-size: 1.35rem;
  color: #ffffff;
}

.handle--changes {
  box-sizing: border-box;
  padding: 0 10px;
  animation: popUp 1s ease;
  @include flexContainer(row, center, center);
  gap: 12px;
  & .cancel--button {
    background-color: $important-color;
  }
}

.option--default {
  background-color: $key-icon-color-a;
  color: #fafafafa;
}

// Wallet section

.wallet--balance--container {
  border-radius: 5px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.72);
  padding: 5px 10px;

  width: 100%;
  @include flexContainer(row, center, center);
  & h2 {
    font-size: 1.3rem;
    width: 100%;
    letter-spacing: 1px;
    @include flexContainer(row, start, center);
    margin-bottom: 12px;
  }
}

.balance--amount {
  font-size: 1.5rem;
  font-weight: 500;
}

.balance--amounts--container {
  @include flexContainer(row, space-between, start);
  flex-basis: 50%;
}

.wallet--button {
  margin-left: 10px;
}

.container--form--table--wallet {
  border-bottom: 2px ridge $client-form-color-gray;
  padding-bottom: 10px;
}

.table--footer {
  animation: popUp 1s ease;
  margin-top: 10px;
  @include flexContainer(row, center, center);

  & button {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
  }
}

.current--page {
  width: 120px;
}

.footer--container {
  @include flexContainer(row, center, center);
  padding: 10px;
  border-radius: 5px;
  gap: 12px;
  width: 100%;
}

.go--to--page--container {
  @include flexContainer(row, space-evenly, center);
  flex-basis: 18%;
  & button {
    @include flexContainer(row, center, center);
    background-color: $table-footer-color;
    border: 1px ridge transparent;
    border-radius: 18px;
    padding: 5px 15px;
    color: #fafafa;
  }

  & button:hover {
    background-color: $on--focus--color;
  }

  & input {
    width: 50px;
    text-align: center;
    border: 1px solid $table-footer-color;
    border-radius: 12px;
  }
}

.arrows--container {
  box-shadow: 0 0.2rem 0.2rem $navbar-bg-color;
  border: 1px solid $client-form-color-gray;
  border-radius: 25px;
  background-color: $table-footer-color;
  font-weight: 700;
  flex-basis: 15%;
  color: #fafafafa;
  @include flexContainer(row, space-evenly, center);

  & p {
    text-align: center;
    font-family: 'Lato';
    padding: 5px 10px;
    border-left: 2px solid #fafafa;
    border-right: 2px solid #fafafa;
  }

  & button {
    @include flexContainer(row, center, center);
    width: 30px;
  }
}

.arrow--footer--table {
  color: #fafafafa;
  font-size: 1.1rem;
}

.add--row--color {
  background-color: $tertiary;
  // & td {color: #fafafa;}
}

.modal--container {
  @include flexContainer(row, center, center);
  height: 105vh;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: $overlay-bg-color;
  & .bg--transparent {
    height: calc(100% + 31rem);
    align-content: center;
    align-items: center;
  }
  &--warning {
    @extend .modal--container;
    top: -70px;
    z-index: 2;
    height: 124vh;
  }
}

.modal--content {
  position: relative;
  width: 60%;
  background-color: $modal-content-background;
  color: #fafafa;
  border-radius: 12px;
  padding-bottom: 15px;

  &--warning {
    @extend .modal--content;
    width: 35%;
    color: $error-color;
  }

  &--info {
    @extend .modal--content;
    width: 35%;
    color: #fafafa;
  }
}

.info--message {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  padding-top: 10px;
}

.warning--icon {
  font-size: 3.5rem;
  width: 100%;
}

.warning--button {
  width: 100%;
  @include flexContainer(row, center, center);
}

.modal--scroll {
  overflow-y: scroll;
  border-radius: 0;
  width: 80%;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-radius: 12px;
  max-height: 80%;
  padding-bottom: 6%;
}

.modal--content--message {
  @extend .modal--content;
  overflow-y: hidden;
}

.modal--scroll::-webkit-scrollbar {
  border-radius: 12px;
  height: 8px;
}

.modal--scroll::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: $client-form-color-gray;
}

.coupon--title {
  position: fixed;
  width: inherit;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  @include flexContainer(row, space-between, center);
  background-color: $navbar-bg-color;
  padding: 10px;
  & h3 {
    flex-basis: 98%;
    width: 100%;
    font-size: 1.2rem;
    text-align: center;
    font-family: 'Lato';
  }

  & button {
    flex-basis: 2%;
    cursor: pointer;
    width: 100%;
    @include flexContainer(row, center, center);
    border-color: transparent;
    background-color: transparent;

    & .close--icon {
      font-size: 2rem;
      color: $close-color;
    }
    & .close--icon:hover {
      color: $close-hover-color;
    }
  }

  &--warning {
    @extend .coupon--title;
    background-color: transparent;
  }
}

.solver--data {
  height: calc(100% - 20px);
  margin-top: 45px;
  color: $navbar-bg-color;
  @include flexContainer(column, space-evenly, center);
  padding: 30px 15px 20px 15px;
  box-sizing: border-box;
  gap: 25px;
  & form {
    @include flexContainer(column, space-evenly, center);
    width: 100%;
    padding: 12px 25px;
  }
  &--info {
    @extend .solver--data;
    padding: 18px 15px 0px 15px;
    margin: 0;
  }
}

.coupon--header {
  padding: 12px;
  @include flexContainer(row, space-between, center);
  color: $navbar-bg-color;
  & p {
    font-size: 1.1rem;
  }
  & span {
    font-size: 1.1rem;
    font-weight: 700;
  }
  border-bottom: 0.5px solid $form-label-color;
  margin: 60px 12px 12px 12px;
}

.coupon--data {
  color: $navbar-bg-color;
  @include flexContainer(column, center, center);
  gap: 15px;
  text-align: center;
  & span {
    font-weight: 700;
  }
}

caption,
legend {
  width: 100%;
  font-size: 1.2rem;
  margin-bottom: 0px;
  color: #fafafafa;
  letter-spacing: 1px;
  background-color: $navbar-bg-color;
  border: 1px solid $navbar-bg-color;
  padding: 7px;
  text-align: center;
}

.button--preview {
  background-color: transparent;
  border: transparent;
  cursor: pointer;
}

.preview--icon {
  font-size: 1.4rem;
}

.button--preview:hover {
  & .preview--icon,
  & .pen--icon {
    color: $key-icon-color-a;
    color: $key-icon-color-a;
  }
}

.cancel--container {
  width: 100%;
  @include flexContainer(column, center, center);
  gap: 25px;
  padding: 12px;
  margin: 18px 12px;
  & p {
    font-size: 1.8rem;
    font-weight: 700;
  }
}

.alert--icon {
  font-size: 5rem;
  color: $error-color;
}

.success--icon {
  font-size: 5rem;
  color: $form--success-color;
}

.reclaim--content {
  @include flexContainer(column, center, start);
  width: 100%;
}

.reclaim--section {
  width: 100%;
  padding-left: 15px;
  & h3 {
    font-size: 1.2rem;
    border-bottom: 1px solid $interline-table-color;
    margin: 2px 0;
  }
  & > div {
    width: 100%;
    padding: 0 12px;
  }
}

.reclaim--field {
  @include flexContainer(row, space-between, center);
  & > span:first-child {
    font-weight: 600;
  }
}

.roles--container {
  @include flexContainer(column, center, center);
  width: 100%;
}
