.transfer-custom-table {
  border-collapse: separate;
  border-spacing: 0 8px;
  
  thead {
    position: relative;
    
    &::after {
      content: "";
      position: absolute;
      bottom: 0px; // Controla la posición de la sombra
      left: 0;
      width: 100%;
      height: 8px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), transparent);
    }
  }
}

.transfer-custom-table tbody tr,
.transfer-custom-table tbody td {
  background-color: transparent !important;
  border-style: none;
}
